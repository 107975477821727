.ulContainer {
  height: 400px;
  width: 900px;
  overflow-y: auto;
  column-count: 2; /* Adjust the number of columns as needed */
  column-gap: 20px; /* Adjust the gap between columns as needed */
}

.ulContainer li {
  /* Optional: Add styling for each list item */
}