.iconic{
    margin-top: 10px;
    margin-right: 6px;
    margin-left: -15px;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow{
    color: white !important;
}

.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon {
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):active > .menu-link .menu-icon {
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
    color: #4400D1 !important;
}

// .menu-icon:hover{
//     color: purple;
// }

.menu-icon:active{
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon {
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):active > .menu-link .menu-icon {
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-icon {
    color: #4400D1 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
    color: #A21094 !important;
}

.svg-icon{
    color: #A21094 !important;
}