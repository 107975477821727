@mixin ScrollBar {
    scrollbar-width: thick;
    transition: all 150ms ease-in-out;
    &::-webkit-scrollbar {
      width: 0.5rem;

      padding-bottom: 0%;
      height: 0.5rem; 
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }
    &:hover::-webkit-scrollbar{
      width: 10px;
    }
  }